import 'waypoints/lib/jquery.waypoints';
import 'waypoints/lib/shortcuts/sticky';
import 'jquery-countdown/src/countdown';
import 'modaal/dist/js/modaal';
import 'velocity-animate/velocity';
import 'velocity-animate/velocity.ui';
import lazyload from 'jquery-lazyload';
import 'scrollmonitor';
import Cookies from 'js-cookie';

;(function () {

    var example = window.example = window.example || {};
    var lightBox;

    example.imageClicked = function (id) {
        lightBox.set_currentItemIndex(parseInt(id, 10) - 1);
        lightBox.show();
    };

    example.radLightBoxLoad = function (sender, eventArgs) {
        lightBox = sender;
    };


})();

(function($) {

    var ieVersion = (function () { if (new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})").exec(navigator.userAgent) !== null) { return parseFloat(RegExp.$1); } else { return false; } })();
    var isIE = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;
    if (ieVersion) {
        var ieVersionclass = 'ie' + ieVersion;
        $('html').addClass(ieVersionclass);
        $('html').addClass('iexplorer');
    }
    else if (isIE) {
        $('html').addClass('ie11').addClass('iexplorer');
    }

    if($('html').hasClass('ie10') || $('html').hasClass('ie9') || $('html').hasClass('ie8') || $('html').hasClass('ie9')){
      $('#outdated').addClass('show');
    }


    $('#btnCloseUpdateBrowser').click(function(e){
      e.preventDefault();
      $('#outdated').removeClass('show');
    });




    var countdownDOM = $('#defaultCountdown');
	var App = {
		secondaryMenu: function() {
			$(document.body).on('click','.nav-item.no-overflow .parent-click', function(e){
				e.preventDefault()
				$(this).parent().toggleClass('clicked');
			});

			$(document.body).on('click', '.gm-select-wrapper', function(){
				$(this).find('.gm-select').toggleClass('open')
			})


			$(document.body).on('click', '.gm-option', function(){
				const all = $('.gm-select')
				if (!$(this).hasClass('selected')) {
					all.find('.gm-option.selected').removeClass('selected')
					all.find('.gm-select__trigger span').text($(this).text())
					all.find('.gm-option[data-value="' + $(this).attr('data-value') + '"]').addClass('selected')


					$('.tab_content').find('div.tabs_item').not('.tabs_item[data-tab-content="' + $(this).attr('data-value')  + '"]').slideUp();
					$('.tab_content').find('div.tabs_item[data-tab-content="' + $(this).attr('data-value')  + '"]').slideDown();
				}
			})

			window.addEventListener('click', function(e) {
			    const select = document.querySelectorAll('.gm-select')
			    $(select).each(function(){
				    if (!this.contains(e.target)) {
				        this.classList.remove('open');
				    }
			    })

			});

		},
		modalCookie: function() {
	  		$('.promomodal').modaal({
	  			background: 'rgba(0,0,0,0.75)',
	  			overlay_opacity: 1,
	  			start_open: false,
	  			custom_class: 'promo-modal',
	  			after_close: function() {
		  			Cookies.set('closed', 'true', {expires: 1});
		  		}
	  		})

			if(!Cookies.get('closed')){
				$('.promomodal').click();
			}

		},

		summaryTabsOpening: function() {
			$(document.body).on('click','[data-result-tab]',function(e){
				e.preventDefault();
				var title = $(this).attr('data-result-tab');
				$('[data-tab-title=' + title + ']').click();
				$('.gm-option[data-value="' + title +'"]').click();
			});
		},
		accordionLinks: function() {

			$('[data-accordion-link]').click(function(e){
				e.preventDefault();
				if($(this).attr('data-accordion-link') === 'closed'){
					$(this).find('span').text('Hide Question');
					$(this).attr('data-accordion-link','opened');
				} else {
					$(this).find('span').text('Show Question');
					$(this).attr('data-accordion-link','closed');
				}
				$(this).parent().parent().find('[data-accordion-content]').slideToggle();
			});
		},
		smoothScroll: function() {
			$('a[href*="#"]')
			  .not('[href="#"]')
			  .not('[href="#0"]')
			  .not('.modaal')
			  .click(function(event) {
			    if (
			      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
			      &&
			      location.hostname == this.hostname
			    ) {
			      // Figure out element to scroll to
			      var target = $(this.hash);
			      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			      // Does a scroll target exist?
			      if (target.length) {
			        // Only prevent default if animation is actually gonna happen
			        event.preventDefault();
			        $('html, body').animate({
			          scrollTop: target.offset().top
			        }, 1000, function() {
			          // Callback after animation
			          // Must change focus!
			          var $target = $(target);
			          $target.focus();
			          if ($target.is(":focus")) { // Checking if the target was focused
			            return false;
			          } else {
			            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
			            $target.focus(); // Set focus again
			          };
			        });
			      }
			    }
			 });
		},
		lazyload: function() {
			$("[data-original]").lazyload({
			    threshold : 200,
			});
		},
		velocityEffects: function(){
			var scaleDownAmnt = 0.75;

			$.Velocity.RegisterEffect("translateUp", {
			  defaultDuration: 1,
			  calls: [
			    [{
			      translateY: '-100%'
			    }, 1]
			  ]
			});
			$.Velocity.RegisterEffect("translateDown", {
			  defaultDuration: 1,
			  calls: [
			    [{
			      translateY: '100%'
			    }, 1]
			  ]
			});
			$.Velocity.RegisterEffect("translateNone", {
			  defaultDuration: 1,
			  calls: [
			    [{
			      translateY: '0',
			      opacity: '1',
			      scale: '1',

			    }, 1]
			  ]
			});
			//scale down
			$.Velocity.RegisterEffect("scaleDown", {
			  defaultDuration: 1,
			  calls: [
			    [{
			      opacity: '0',
			      scale: '0.7',

			    }, 1]
			  ]
			});

			//gallery
			$.Velocity.RegisterEffect("scaleDown.moveUp", {
			  defaultDuration: 1,
			  calls: [
			    [{
			      translateY: '0%',
			      scale: scaleDownAmnt,

			    }, 0.20],
			    [{
			      translateY: '-100%'
			    }, 0.60],
			    [{
			      translateY: '-100%',
			      scale: '1',

			    }, 0.20]
			  ]
			});
			$.Velocity.RegisterEffect("scaleDown.moveUp.scroll", {
			  defaultDuration: 1,
			  calls: [
			    [{
			      translateY: '-100%',
			      scale: scaleDownAmnt,

			    }, 0.60],
			    [{
			      translateY: '-100%',
			      scale: '1',

			    }, 0.40]
			  ]
			});
			$.Velocity.RegisterEffect("scaleUp.moveUp", {
			  defaultDuration: 1,
			  calls: [
			    [{
			      translateY: '90%',
			      scale: scaleDownAmnt,

			    }, 0.20],
			    [{
			      translateY: '0%'
			    }, 0.60],
			    [{
			      translateY: '0%',
			      scale: '1',

			    }, 0.20]
			  ]
			});
			$.Velocity.RegisterEffect("scaleUp.moveUp.scroll", {
			  defaultDuration: 1,
			  calls: [
			    [{
			      translateY: '0%',
			      scale: scaleDownAmnt,

			    }, 0.60],
			    [{
			      translateY: '0%',
			      scale: '1',

			    }, 0.40]
			  ]
			});
			$.Velocity.RegisterEffect("scaleDown.moveDown", {
			  defaultDuration: 1,
			  calls: [
			    [{
			      translateY: '0%',
			      scale: scaleDownAmnt,

			    }, 0.20],
			    [{
			      translateY: '100%'
			    }, 0.60],
			    [{
			      translateY: '100%',
			      scale: '1',

			    }, 0.20]
			  ]
			});
			$.Velocity.RegisterEffect("scaleDown.moveDown.scroll", {
			  defaultDuration: 1,
			  calls: [
			    [{
			    }, 0.60],
			    [{
			      translateY: '100%',
			      scale: '1',
			    }, 0.40]
			  ]
			});
			$.Velocity.RegisterEffect("scaleUp.moveDown", {
			  defaultDuration: 1,
			  calls: [
			    [{
			      translateY: '-90%',
			      scale: scaleDownAmnt,

			    }, 0.20],
			    [{
			      translateY: '0%'
			    }, 0.60],
			    [{
			      translateY: '0%',
			      scale: '1',
			    }, 0.20]
			  ]
			});
		},
		sliderLoad: function(){
			  var settings = {
			    sliderInit: $('.slider').data('block-slider'),
			    sliderSlide: $('.block-slide__elem'),
			    slideBg: '.block-slide__bg',
			    navNext:  $('.slider-nav').find('a.js-slider-next'),
			    sliderBtn:   $('.block-slide__btn'),
			    delta: 0,
			    scrollThreshold: 7,
			    currentSlide: 1,
			    animating: false,
			    heightQ: '.question-answers',
			    animationDuration: 1200
			  };


			  var delta = 0,
			      animating = false;

			  return {


				init: function() {
				    this.bind();
				},

			    bind: function(){

			      settings.sliderSlide.first().addClass('is-active');




			      if (settings.sliderInit == 'on') {
			        App.sliderLoad().initScroll();

			      }
			      settings.navNext.on('click', App.sliderLoad().nextSlide);


			      $('[data-slider=next]').on('click', function(){
			      	if($(this).attr('data-showanswer') === "true"){

					      	$(this).children('input').prop("checked", true);
					      	$(this).addClass('checked');
					      	$(this).parentsUntil('.question-answers').find('.answer-show').find('.inner').slideDown();
					      	setTimeout(function(){
					      		App.sliderLoad().nextSlide();
					      	},900);



			      	} else {
				      	$(this).children('input').prop("checked", true);
				      	App.sliderLoad().nextSlide();
			      	}
			      });




			      App.sliderLoad().checkNavigation();

			    },
			    setAnimation: function(midStep, direction) {

			      var animationVisible = 'translateNone',
			          animationTop = 'translateUp',
			          animationBottom = 'translateDown',
			          easing = 'ease',
			          animDuration = settings.animationDuration;

			      if (midStep) {
			        animationVisible = 'scaleUp.moveUp.scroll';
			        animationTop = 'scaleDown.moveUp.scroll';
			        animationBottom = 'scaleDown.moveDown.scroll';

			      } else {
			        animationVisible = (direction == 'next') ? 'scaleUp.moveUp' : 'scaleUp.moveDown';
			        animationTop = 'scaleDown.moveUp';
			        animationBottom = 'scaleDown.moveDown';
			      }

			      return [animationVisible, animationTop, animationBottom, animDuration, easing];
			    },


			    initScroll: function() {

			       if($(window).width() > 1199){

				      $('[data-block-slider]').velocity({
				      	height: settings.sliderSlide.filter('.is-active').find(settings.heightQ).outerHeight()
				      }, {
				      	duration: 100
				      });
				      var visibleSlide = settings.sliderSlide.filter('.is-active'),
				          topSection = visibleSlide.prev(settings.sliderSlide),
				          bottomSection = visibleSlide.next(settings.sliderSlide),
				          animationParams = App.sliderLoad().setAnimation(false),
				          animationVisible = animationParams[0],
				          animationTop = animationParams[1],
				          animationBottom = animationParams[2];

				      visibleSlide.children('div').velocity(animationVisible, 1, function() {
				        visibleSlide.css('opacity', 1);
				        topSection.css('opacity', 1);
				      });

				      topSection.children('div').velocity(animationTop, 0);
			      	  bottomSection.children('div').velocity(animationBottom, 0);
			       } else {



				      var visibleSlide = settings.sliderSlide.filter('.is-active'),
				          topSection = visibleSlide.prev(settings.sliderSlide),
				          bottomSection = visibleSlide.next(settings.sliderSlide);

				        visibleSlide.css('opacity', 1);
				        topSection.css('opacity', 1);
			       }




			    },
			    nextSlide: function(e) {
			    	if($(window).width() > 1199){

			      typeof e !== 'undefined' && e.preventDefault();

			      var visibleSlide = settings.sliderSlide.filter('.is-active'),
			          animationParams = App.sliderLoad().setAnimation(midStep, 'next'),
			          midStep = false;


			      if (!animating && !visibleSlide.is(":last-of-type")) {

			        animating = true;


			        $('body').css('overflow','hidden');


			        $('[data-block-slider]').velocity({
				        	height: visibleSlide.next(settings.sliderSlide).find(settings.heightQ).outerHeight()
				        }, {
				        	duration: settings.animationDuration
				    });

			        setTimeout(function(){
			        	visibleSlide.css('opacity', 0);
			        }, settings.animationDuration);

			        visibleSlide
			          .children(settings.slideBg)
			          .velocity(animationParams[1], animationParams[3])
			          .end()
			          .next(settings.sliderSlide)
			          .css('opacity', 1)
			          .children(settings.slideBg)
			          .velocity(animationParams[0], animationParams[3], function() {
			            animating = false;
			        });
			        setTimeout(function(){
			        	visibleSlide.removeClass('is-active');
			        	visibleSlide.next(settings.sliderSlide).addClass('is-active');

				        $('body').css('overflow','auto');
			        }, settings.animationDuration);
			        var currentSlide = settings.currentSlide + 1;
			      }
			      App.sliderLoad().resetScroll();
			  } else {
			      var visibleSlide = settings.sliderSlide.filter('.is-active');
			      if (!visibleSlide.is(":last-of-type")) {
			        visibleSlide.hide();
			        visibleSlide.removeClass('is-active');
			        visibleSlide.next(settings.sliderSlide).addClass('is-active');
			      }
			  }
			    },
			    resetScroll: function() {
			      delta = 0;
			      App.sliderLoad().checkNavigation();
			    },
			    checkNavigation: function() {

			      if(settings.sliderSlide.filter('.is-active').is(':last-of-type')) {
			      	settings.navNext.addClass('inactive')
			      } else {
			      	settings.navNext.removeClass('inactive')
			      }

			    },
			  };
		},
		startDate: 0,
		timingDOM: countdownDOM.attr('data-timing')*1000*60,
		searchSubtopics: function() {
		    var input, filter, ul, li, a, i;
		    $('[data-filter=subtopics]').keyup(function(){
			    input = $(this);
			    filter = input.val().toUpperCase();
			    ul = input.parent().parent().find('ul');
			    li = ul.find('li');
			    for (i = 0; i < li.length; i++) {
			        a = li[i].getElementsByTagName("a")[0];
			        if (a.innerHTML.toUpperCase().indexOf(filter) > -1) {
			            li[i].style.display = "";
			        } else {
			            li[i].style.display = "none";
			        }
			    }
		    });
		},
	  	tabsLoad: function(){



			if (window.location.href.indexOf("section3?topic=") > -1) {
				var index = $('.tab ul.tabs li.current').index();
				$('.tab_content').find('div.tabs_item').not('div.tabs_item:eq(' + index + ')').slideUp();
				$('.tab_content').find('div.tabs_item:eq(' + index + ')').slideDown();

			} else {
				$('.tab ul.tabs').addClass('active').find('> li:eq(0)').addClass('current');
			}


			$('.tab ul.tabs li a').click(function (g) {

				var tab = $(this).closest('.tab'),
					index = $(this).closest('li').index();

				tab.find('ul.tabs > li').removeClass('current');
				$(this).closest('li').addClass('current');

				tab.find('.tab_content').find('div.tabs_item').not('div.tabs_item:eq(' + index + ')').slideUp();
				tab.find('.tab_content').find('div.tabs_item:eq(' + index + ')').slideDown();

				g.preventDefault();
				if($('.sticky-test__header').length){
					setTimeout(function(){
						$('html, body').animate({
				          scrollTop: 0
				        }, 500);
					},500);
				}


			});
	  	},
	  	toolTipLoad: function(){
	  		if($('.cms-admin').length){
	  		 $('[data-toggle="tooltip"]').tooltip();
	  		}
	  	},
	  	adminHeadFixed: function(){
	  		if($('.header-page').attr('data-fixed') == 'fixed'){
	  			$('body').addClass('fixed-head');
	  			var sticky = new Waypoint.Sticky({
				  element: $('.header-page')[0]
				});
	  		}
	  		if($('.cms-admin').length){
	  			var heightNav = $('.sidebar-dash').find('.inner-fix');
	  			var stickyAdmin;
	  			if($(window).height() > heightNav.outerHeight()){
		  			stickyAdmin = new Waypoint.Sticky({
					  element: heightNav,
					});
	  			}

	  		}
	  	},
	  	modaalLoad: function(){

	  		$('.modaal').modaal({
	  			background: '#fff',
	  			overlay_opacity: 0.8
	  		});

			$('[data-trigger-iframe]').modaal({
				type: 'iframe',
				width: 1200,
				height: 700,
	  			background: '#fff',
	  			overlay_opacity: 0.8
			});
	  	},
	  	loginHome: function(){
	  		$('[data-forgot]').click(function(e){
	  			e.preventDefault();
	  			$('.forgot-password').slideToggle();
	  		});
	  		if($('.forgot-password').find('.description').length){
	  			$('.forgot-password').show();
	  		}
	  		function fadeOutContentHome(){
	  			$('.main-cont').toggleClass('hide');
	  			$('.overlay').toggleClass('fadeout');
	  		}
	  		$('[data-video-trigger]').modaal({
	  			type: 'video',
	  			background: '#fff',
	  			overlay_close: true,
	  			overlay_opacity: 0.9,
				before_open: function() {
					fadeOutContentHome();
				},
				after_close: function() {
					fadeOutContentHome();
				},
	  		});
	  	},
	  	isrunningTest: false,
	  	stopTest: function(){
	  		countdownDOM.countdown('stop');
	  		$('.pause__button').removeClass('show_pause');
	  		$('.pause__button').addClass('show_play');
	  		App.isrunningTest = false;
	  	},
	  	resumeTest: function(){
	  		var hoursRem = $('[data-time=hours]').text() * 1000 * 60 * 60 ,
	  			minsRem = $('[data-time=mins]').text() * 1000 * 60,
	  			secsRem = $('[data-time=secs]').text() * 1000;

	  		var timingRestDOM = hoursRem + minsRem + secsRem;
	  		App.startDate = new Date().getTime();
			countdownDOM.countdown(App.startDate + timingRestDOM, function(event) {
			  var $this = $(this).html(event.strftime(''
			    + '<div class="block__time"><span class="time" data-time="hours">%H</span><span class="label">hours</span></div>'
			    + '<div class="block__time"><span class="time" data-time="mins">%M</span><span class="label">mins</span></div>'
			    + '<div class="block__time"><span class="time" data-time="secs">%S</span><span class="label">secs</span></div>'));
			}).on('finish.countdown', App.finishTest);
			$('.pause__button').removeClass('show_play');
			$('.pause__button').addClass('show_pause');
	  		$('[data-countdown=start]').hide();
	  		$('[data-countdown=finish]').show();
	  		App.isrunningTest = true;
	  	},
	  	finishTest: function(){
	  		countdownDOM.countdown('pause');
	  		$('.finished-test').click();
	  		$('.finished-essay').click();
	  		App.isrunningTest = false;
	  	},
	  	exitTest: function() {
	  		countdownDOM.countdown('stop');
	  		$('.pause__button').removeClass('show_pause');
	  		$('.pause__button').addClass('show_play');
	  		$('.exit-test').click();
	  		App.isrunningTest = false;
	  	},
	  	startTest: function(){
	  		App.startDate = new Date().getTime();
	  		countdownDOM.countdown(App.startDate + App.timingDOM);
	  		$('.pause__button').addClass('show_pause');
	  		$('[data-countdown=start]').hide();
	  		$('[data-countdown=finish]').show();
	  		App.isrunningTest = true;
	  	},
	  	timingLoad: function() {
	  		App.startDate = new Date().getTime();
			countdownDOM.countdown(App.startDate + App.timingDOM, function(event) {
			  var $this = $(this).html(event.strftime(''
			    + '<div class="block__time"><span class="time" data-time="hours">%H</span><span class="label">hours</span></div>'
			    + '<div class="block__time"><span class="time" data-time="mins">%M</span><span class="label">mins</span></div>'
			    + '<div class="block__time"><span class="time" data-time="secs">%S</span><span class="label">secs</span></div>'));
			}).on('finish.countdown', App.finishTest);


			countdownDOM.countdown('stop');

			if($('.sticky-test__header').length){
	  			var stickyHeaderTest = new Waypoint.Sticky({
				  element: $('.sticky-test__header')[0]
				});
			}

			$('[data-countdown=start]').click(function(){
				App.startTest();
			});


			$('[data-test=next]').click(function(e){
				e.preventDefault();
				if(App.isrunningTest === false && !$('[data-summary=show]').length){
					App.startTest();
				} else {
					App.resumeTest();
				}
				$('.tabs').find('li.current').next().find('.btn-tabs').click();
				$('.gm-option.selected').parent().next('span').find('.gm-option').click();
			});


			$('[data-countdown=finish]').click(function(){
		      	if($(this).attr('data-showanswer') === "true"){

			      	$(this).children('input').prop("checked", true);
			      	$(this).addClass('checked');
			      	$(this).parentsUntil('.question-answers').find('.answer-show').find('.inner').slideDown();
			      	setTimeout(function(){
			      		App.finishTest();
			      	},900);

		      	} else {
		      		App.finishTest();
		      	}

			});

			$('[data-countdown=exit]').click(function(){
				App.exitTest();
			});

			$('.pause__button').click(function(e){
				e.preventDefault();
				if(App.isrunningTest === false){
					App.resumeTest();
				} else {
					App.stopTest();
				}
				$(this).toggleClass('paused');
			});

			$('[data-countdown=retake]').click(function(e){
				e.preventDefault();
				window.open(window.location.href, '_blank');
			    window.close();
			});



			$('.options__answ').find('label').click(function(){
				if(App.isrunningTest === false && !$('[data-summary=show]').length){
					App.startTest();
				} else if(App.isrunningTest === false &&  $('[data-summary=show]').length) {
				} else {
					App.resumeTest();
				}

			});


			$('.textarea-input').focus(function(){
				if(App.isrunningTest === false ){
					if($('.pause__button').hasClass('paused')){
						$('.pause__button').removeClass('paused');
						App.resumeTest()
					} else {
						App.startTest()
					}
				} else {
					$('.pause__button').removeClass('paused');
					App.resumeTest();
				}

			});

			$('[data-start-diagnostic]').click(function(e) {
				e.preventDefault();
				if(App.isrunningTest === false){
					App.startTest();
				} else {
					App.resumeTest();

				}
			});


			if($('[data-summary=show]').length){
				$('.btn-summary__head').show();
				$('.btn-summary').show();
				$('[data-countdown=start]').hide();
				$('[data-countdown=retake]').show();

				$('.tabs.active').find('li').removeClass('current');
				$('.tabs.active').find('li').last().addClass('current');
				$('.tabs_item').slideUp();
				$('.tabs_item').last().slideDown();


				$('.answer__result').each(function(){
					$(this).parent().find('.options__answ').find('.' + $(this).find('input[type=hidden]').val()).addClass('correct').prepend('<img src="/app/images/icons/tick.svg" class="correct">');
					$(this).parent().find('.options__answ').find('input:checked').parent('li').addClass('selected').prepend('<img src="/app/images/icons/cross.svg" class="incorrect">');
				});



			}

			$('.btn-summary__head').click(function(){
				$('.btn-summary').click();
			});

			$(".finished-test").modaal({
				type: 'confirm',
				hide_close: true,
	  			background: 'rgb(236, 239, 241)',
	  			overlay_opacity: 0.9,
	  			overlay_close: false,
			    confirm_button_text: 'Ok',
			    confirm_cancel_button_text: 'Cancel',
			    confirm_title: 'Test Completed',
			    confirm_content: '',
			    confirm_callback: function() {
			    	$('[data-countdown=finish]').click();
				},
				custom_class: 'finished__modal',

			});
			$('.finished-essay').modaal({
				type: 'confirm',
				hide_close: false,
	  			background: 'rgb(236, 239, 241)',
	  			overlay_opacity: 0.9,
	  			overlay_close: false,
			    confirm_button_text: 'Save and exit',
			    confirm_cancel_button_text: 'Exit without saving',
			    confirm_title: 'Time completed',
			    confirm_content: '',
				custom_class: 'finished__modal',
			    confirm_callback: function() {
			    	$('[data-countdown=finish]').click();
				},
				confirm_cancel_callback: function() {
					window.close();

				},
			});

			$(".exit-test").modaal({
				type: 'confirm',
				hide_close: true,
	  			background: 'rgb(236, 239, 241)',
	  			overlay_opacity: 0.9,
	  			overlay_close: false,
			    confirm_button_text: 'Confirm exit',
			    confirm_cancel_button_text: 'Cancel',
			    confirm_title: 'Exit?',
			    confirm_content: '',
			    confirm_callback: function() {
			       	window.location.href = '/dashboard';
				},
				custom_class: 'exit__modal',
			});
			if(!$('[data-summary=show]').length){
				$('.options__answ li').dblclick(function(e){
					e.preventDefault();
					$(this).toggleClass('discarded');
				});
			}
	  	},
	  	statsAppear: function() {
	  		if($('.progress-bar-elem').length){
	  			$('.progress-bar-elem').each(function(){
	  				var myElement = $(this)[0];
					var elementWatcher = scrollMonitor.create( myElement );
					var percentWidth = $(this).find('.correct').attr('data-progress') + "%";
					elementWatcher.enterViewport(function() {
						$(myElement).find('.correct').velocity({ width: percentWidth }, {
						    duration: 400,
						    easing: "swing",
						    queue: "",
						    begin: undefined,
						    progress: undefined,
						    complete: function(){
						    	$(myElement).find('.correct').addClass('complete');
						    },
						    display: undefined,
						    visibility: undefined,
						    loop: false,
						    delay: false,
						    mobileHA: true
						});
					});


	  			});
	  		}
	  	},
	  	materialForm: function() {



  	  	$('.form-control').each(function(){
  	  		if($(this).val()){
  	  			$(this).closest('.md-form').addClass('focus has-value');
  	  		}
  	  	});
          $('.form-control').focus(function() {
            $(this).closest('.md-form').addClass('focus has-value');
          }).focusout(function() {
            $(this).closest('.md-form').removeClass('focus');
          }).blur(function() {
            if (!this.value) {
              $(this).closest('.md-form').removeClass('has-value');
            }
            $(this).closest('.md-form').removeClass('focus');
          });



	  	},



	};

	var Sage = {

	    'common': {
	      init: function() {
	      	App.lazyload();
	        App.tabsLoad();
	        App.adminHeadFixed();
	        App.loginHome();
	        App.timingLoad();
	        App.toolTipLoad();
	        App.modaalLoad();
	        App.searchSubtopics();
	      	App.velocityEffects();
	      	App.materialForm();
	      	App.accordionLinks();
	      },

	      finalize: function() {
	      	App.sliderLoad().init();
	      	App.statsAppear();
	      	App.smoothScroll();
	      	App.summaryTabsOpening();
	      	App.modalCookie();
	      	App.secondaryMenu();

		  	$('#menu-button').click(function(e){
		  		e.preventDefault();
		  		$('.toggle-mobile-nav').slideToggle();
		  	});


	      }
	    }
	};

	var UTIL = {
	    fire: function(func, funcname, args) {
	      var fire;
	      var namespace = Sage;
	      funcname = (funcname === undefined) ? 'init' : funcname;
	      fire = func !== '';
	      fire = fire && namespace[func];
	      fire = fire && typeof namespace[func][funcname] === 'function';

	      if (fire) {
	        namespace[func][funcname](args);
	      }
	    },
	    loadEvents: function() {
	      UTIL.fire('common');
	      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
	        UTIL.fire(classnm);
	        UTIL.fire(classnm, 'finalize');
	      });
	      UTIL.fire('common', 'finalize');
	    }
	};


	$(document).ready(UTIL.loadEvents);

})(jQuery);
